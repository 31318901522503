import { crptlAvatar } from './utils';
import { DOTHTML } from './config';

//DESKTOP
const dsWalletSign = document.querySelector('.section-header-ds__wallet-sign');
const dsWalletStatus = document.querySelector('.section-header-ds__wallet-stat');
const dsWalletBlock = document.querySelector('.section-header-ds__wallet');
const dsAccountBlock = document.querySelector('.section-header-ds__account');
const avatarWrapper = document.querySelector('.section-header-ds__account-wrapper');

//MOBILE
const mbWalletSign = document.querySelector('.section-header-mobile__wallet-sign');
const mbWalletStatus = document.querySelector('.section-header-mobile__wallet-stat');
const mbWalletBlock = document.querySelector('.section-header-mobile__wallet');
const mbProfileBtn = document.querySelector('.section-header-mobile__profile');
var mbMenuLish = document.querySelector('.section-header-mobile__list');

const initHeader = async function () {
  try {
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length) {
      activateWallet(accounts[0]);
    } else {
      deactivateWallet();
    }
  } catch (error) {
    deactivateWallet();
  }
};

const connectWallet = async function () {
  if (typeof window.ethereum !== 'undefined') {
    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      initHeader();
    } catch (error) {
      window.location.href = `connect-wallet${DOTHTML}`;
    }
  } else {
    window.location.href = `connect-wallet${DOTHTML}`;
  }
};

const activateWallet = function (address) {
  dsWalletSign.textContent = 'WALLET CONNECTED';
  dsWalletSign.style.color = '#7EE4F4';
  dsWalletStatus.style.backgroundColor = '#7EE4F4';
  dsWalletBlock.href = '#';
  dsAccountBlock.classList.remove('hidden');
  dsWalletBlock.removeEventListener('click', connectWallet);
  avatarWrapper.textContent = '';
  avatarWrapper.insertAdjacentHTML('afterbegin', crptlAvatar(address));

  mbWalletSign.textContent = 'WALLET CONNECTED';
  mbWalletSign.style.color = '#7EE4F4';
  mbWalletStatus.style.backgroundColor = '#7EE4F4';
  mbWalletBlock.href = '#';
  mbProfileBtn.classList.remove('hidden');
  mbWalletBlock.removeEventListener('click', connectWallet);
};

const deactivateWallet = function () {
  dsWalletSign.textContent = 'CONNECT WALLET';
  dsWalletSign.style.color = '#ff008a';
  dsWalletStatus.style.backgroundColor = '#ff008a';
  dsWalletBlock.href = '#';
  avatarWrapper.textContent = '';
  if (dsAccountBlock.classList.contains('hidden')) {
  } else {
    dsAccountBlock.classList.add('hidden');
  }
  dsWalletBlock.addEventListener('click', connectWallet);

  mbWalletSign.textContent = 'CONNECT WALLET';
  mbWalletSign.style.color = '#ff008a';
  mbWalletStatus.style.backgroundColor = '#ff008a';
  mbWalletBlock.href = '#';
  mbProfileBtn.classList.add('hidden');
  mbWalletBlock.addEventListener('click', connectWallet);
};


//START CHANGING MENU
const observer = new MutationObserver((mutationsList, observer) => {
  observer.disconnect();
  mutationsList.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
      if (mutation.target.style.cssText.includes('transform: translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;')){
        mutation.target.style.cssText += ' display: none;';
      } else {
        const currentStyle = mutation.target.style.cssText;
        mutation.target.style.cssText = currentStyle.replace('display: none;', '');
      }
    }
  });
  observer.observe(mbMenuLish, { attributes: true, attributeFilter: ['style'] });
});

observer.observe(mbMenuLish, { attributes: true, attributeFilter: ['style'] });

if (mbMenuLish.style.cssText.includes('transform: translate3d(0px, 0px, 0px) scale3d(0, 0, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;')){
  mbMenuLish.style.cssText += ' display: none;';
}
//END CHANGING MENU

try {
  ethereum.on('accountsChanged', (accounts) => {
    avatarWrapper.textContent = '';
    initHeader();
  });
} catch (error) {}

initHeader();